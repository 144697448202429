<template>
  <div class="end-page desktop-container bg-center m-auto base__line">
    <div class="desktop-screen relative m-auto">
      <div class="bg-mobile bg-center pt-4 bg-top pb-8">
        <div class="text-white base__line">
          <logo-icon></logo-icon>
          <img
            width="230"
            height="135"
            class="img-title m-auto mt-4"
            src="../assets/register/title.jpg"
            alt
            srcset
          />
        </div>
        <section class="mt-3 mx-auto form">
          <div class="end-header mb-6">
            <div class="content encart-shadow bg-secondary m-auto mb-4 p-4 pb-5 text-white font-lato">
              <div class="h-full center-vertically">
                <div class="relative">
                  <p class="text-md text-center" v-html="$t(`end.description`)"></p>
                </div>
              </div>
            </div>
          </div>

          <div class="encart find-encart encart-shadow bg-secondary m-auto p-4">
            <img
              class="seum-img m-auto"
              src="../assets/confirm/titre_seum@2x.png"
              alt
              srcset
            />
            <img
              class="card-img m-auto"
              src="../assets/confirm/credit cards@2x.png"
              alt
              srcset
            />
            <p
              class="text-lg text-center text-white font-latoBold" 
              v-html="$t(`diffusion.find`)"
            ></p>

            <span class="table m-auto mb-3">
              <NCta
                borderRadius="30px"
                backgroundColor="#FFD400"
                textColor="#014457"
                data-cy="submit-register"
                @click.native="exitUrl(1)"
                designType="round"
                ref="form_submit_button"
              >
              <span class="text-md font-oswaldBold uppercase">{{$t('end.submit')}}</span></NCta>
            </span>

          </div>
          
        </section>
      </div>
      <SiteFooter></SiteFooter>
    </div>
    <div class="gaming-bg"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isProdEnv, getDeviceType } from "../helpers";
import design from '@/validators/design'
import LogoIcon from "@/components/icons/LogoIcon";


export default {
  name: "EndPage",
  components: {
    LogoIcon
  },
  methods: {
    ...mapActions(["updateModalConfig"]),
    exitUrl(linknumber) {
      const iduParam = this.idu != null ? "&iIdU=" + this.idu : "";
      window.open(
        `${document.location.protocol}//${document.location.host}/api/v0/${this.appConfig.baseName.replace(/[_-]/, '_')}/v1/track/page_click?token=${this.token}${iduParam}&iPageId=${this.$route.meta.pageId}&iLinkNumber=${linknumber}&iVisitId=${this.visitId}`,
        "_blank"
      );
    },
    trackClick(linkId) {
      const oTrackData = {
        iIdU: +this.idu,
        iPageId: this.$route.meta.pageId,
        iLinkNumber: linkId,
        iVisitId: +this.visitId,
      };

      this.$requestAPI.trackPageClick(oTrackData)
        .catch(console.log);
    }
  },
  computed: {
    ...mapGetters({
      decliConfig: "config/activeDecliConfig",
      token: "global/token",
      idu: "user/idu",
      visitId: "global/visitId",
      appConfig: "config/appConfig",
    })
  }
};
</script>

<style lang="scss" scoped>
.end-page {
  line-height: 20px;
  .encart-shadow {
    box-shadow: 0px 3px 6px #0D435580;
  }
  .end-header {
    .content {
      max-width: 324px;
      width: 100%;
      height: 100%;
    }
  }
  .encart {
    max-width: 340px;
    width: 100%;
    border-radius: 10px;
    border: 2px solid #FFFFFF;
  }
  .find-encart {
    .n-cta ::v-deep {
      width: 200px;
      padding: 12px 25px !important;
      background-color: #FFD400 !important;
      font-family: "Oswald Bold";
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .img-title {
    width: 230px;
    height: 135px;
  }
  .score-img {
    max-width: 360px;
  }
  .seum-img {
    max-width: 288px;
  }
  .card-img {
    max-width: 138px;
  }
}
.virals {
  max-width: 374px;
  margin: auto;
  margin-left: 1rem;
}
.n-cta ::v-deep {
  @apply mt-4;
  svg {
    background-color: #FFD400;
  }
}
</style>
